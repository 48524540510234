<template>
    <div class="flex justify-center px-4">
      <div class="w-full max-w-md bg-white p-8 shadow rounded-lg flex flex-col items-center pt-4 mt-5">
        <div class="w-full text-right">
          <font-awesome-icon icon="trash-alt" class="cursor-pointer text-primary" @click="deleteUser()"/>
        </div>
        <form class="w-full" v-if="user">
          <div class="mb-2">
            <label class="block text-sm font-bold mb-2" for="name">
              Naam
            </label>
            <input class="border rounded w-full py-2 px-3 leading-tight text-black bg-gray-100" :class="[error && !name ? 'border-primary' : '']" id="name" type="text" placeholder="Naam" v-model="name" required>
          </div>
          <div>
            <label class="block text-sm font-bold mb-2" for="roles">
              Rollen
            </label>
            <multiselect v-if="allRoles" :multiple="true" v-model="roles" deselect-label="Verwijder" select-label="Selecteer" selected-label="Geselecteerd" placeholder="Selecteer rollen" track-by="name" label="name" :options="allRoles" :searchable="false" :allow-empty="true">
            </multiselect>
          </div>
          <div class="mt-4">
            <p v-if="error" class="text-primary text-sm font-semibold">{{ error }}</p>
          </div>
          <div class="rounded-lg text-white px-4 py-3 text-sm cursor-pointer bg-primary text-center mt-4" @click="editUser()">
            Gebruiker aanpassen
          </div>
        </form>
      </div>
    </div>
</template>

<script>

export default {
  title: 'Gebruiker aanpassen',
  name: 'EditUser',
  data () {
    return {
      user: null,
      name: null,
      roles: [],
      allRoles: null,
      error: null
    }
  },
  mounted () {
    if (!this.$store.getters.users) {
      this.$store
        .dispatch('getUsers')
        .then(() => this.setUser())
        .catch(() => this.setError('Er is iets misgegaant'))
    } else this.setUser()
  },
  methods: {
    setUser () {
      this.user = this.$store.getters.users.find(user => String(user.id) === this.$route.params.id)
      this.name = this.user.name
      if (!this.$store.getters.allRoles) {
        this.$store
          .dispatch('getRoles')
          .then(() => this.setRoles())
          .catch(() => this.setError('Er is iets misgegaan'))
      } else this.setRoles()
    },
    setRoles () {
      this.allRoles = this.$store.getters.allRoles
      this.allRoles.forEach(role => {
        if (this.user.roles.includes(role.name)) this.roles.push(role)
      })
    },
    editUser () {
      const name = this.name
      const roles = this.roles.map(role => role.id)
      const id = this.user.id
      if (!name) return this.setError('Niet alles is juist ingevuld')
      this.$store
        .dispatch('editUser', { name, roles, id })
        .then(() => this.$router.push('/users'))
        .catch(err => this.setError(err))
    },
    deleteUser () {
      const id = this.user.id
      this.$store
        .dispatch('deleteUser', { id })
        .then(() => this.$router.push('/users'))
        .catch(err => this.setError(err))
    },
    setError (data) {
      this.error = data
    }
  }
}
</script>
